import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-217c4b8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "photo-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_BasicImageList = _resolveComponent("BasicImageList")!
  const _component_BasicFormDialog = _resolveComponent("BasicFormDialog")!

  return (_openBlock(), _createBlock(_component_BasicFormDialog, {
    modelValue: _ctx.modalShow,
    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.modalShow) = $event)),
    title: "交易单详情",
    footerButtons: _ctx.footerButtons,
    width: "1200px",
    "label-width": "125px",
    top: "7vh",
    closeOnClickModal: true,
    onClosed: _cache[18] || (_cache[18] = ($event: any) => (_ctx.fileList.length = 0))
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_form, {
        class: "dialog-form",
        "label-width": "130px",
        inline: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "tradeCode",
            size: "small",
            label: "交易单号"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.tradeCode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.tradeCode) = $event)),
                style: {"width":"100%"}
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "publisherName",
            size: "small",
            label: "发布方"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.publisherName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.publisherName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "sourceType",
            size: "small",
            label: "交易类型"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                disabled: true,
                modelValue: _ctx.formData.sourceType,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.sourceType) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "求购",
                    value: 1
                  }),
                  _createVNode(_component_el_option, {
                    label: "供应",
                    value: 2
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "contact",
            size: "small",
            label: "联系人"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.contact,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.contact) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "phone",
            size: "small",
            label: "联系人电话"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.phone,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.phone) = $event)),
                style: {"width":"100%"}
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "publishTime",
            size: "small",
            label: "发布时间"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.publishTime,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.publishTime) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "finishTime",
            size: "small",
            label: "结束时间"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.finishTime,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.finishTime) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "categoryName",
            size: "small",
            label: "货物类别"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.categoryName,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.categoryName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "goodsName",
            size: "small",
            label: "货物名称"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.goodsName,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.goodsName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "description",
            size: "small",
            label: "货物规格"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.description,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formData.description) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "countMode",
            size: "small",
            label: "计价方式"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                disabled: true,
                modelValue: _ctx.formData.countMode,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formData.countMode) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "体积",
                    value: 1
                  }),
                  _createVNode(_component_el_option, {
                    label: "重量",
                    value: 2
                  }),
                  _createVNode(_component_el_option, {
                    label: "数量",
                    value: 3
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "unitPrice",
            size: "small",
            label: "单价"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.unitPrice,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.formData.unitPrice) = $event)),
                style: {"width":"200px"}
              }, {
                suffix: _withCtx(() => [
                  _createTextVNode(" 元 / " + _toDisplayString(_ctx.getUnitName(_ctx.formData.countMode)), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "createdTime",
            size: "small",
            label: _ctx.getCountModeName(_ctx.formData.countMode)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData[_ctx.getAmountField(_ctx.formData.countMode)],
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.formData[_ctx.getAmountField(_ctx.formData.countMode)]) = $event)),
                style: {"width":"200px"}
              }, {
                suffix: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getUnitName(_ctx.formData.countMode)), 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            prop: "totalPrice",
            size: "small",
            label: "总价"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                disabled: true,
                modelValue: _ctx.formData.totalPrice,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formData.totalPrice) = $event)),
                style: {"width":"200px"}
              }, {
                suffix: _withCtx(() => [
                  _createTextVNode(" 元 ")
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "publishStatus",
            size: "small",
            label: "发布状态"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                disabled: true,
                modelValue: _ctx.formData.publishStatus,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formData.publishStatus) = $event)),
                style: {"width":"200px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_option, {
                    label: "未发布",
                    value: 1
                  }),
                  _createVNode(_component_el_option, {
                    label: "发布中",
                    value: 2
                  }),
                  _createVNode(_component_el_option, {
                    label: "已结束",
                    value: 3
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "address",
            size: "small",
            label: "收货地址"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                style: {"width":"882px"},
                disabled: true,
                modelValue: _ctx.formData.address,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formData.address) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, {
            prop: "tradeDescription",
            size: "small",
            label: "描述"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                style: {"width":"882px"},
                disabled: true,
                placeholder: "暂无描述",
                modelValue: _ctx.formData.tradeDescription,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formData.tradeDescription) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BasicImageList, {
          fileList: _ctx.fileList,
          disabled: true,
          size: "large"
        }, null, 8, ["fileList"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "footerButtons"]))
}