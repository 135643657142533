
import BasicFormDialog from "@/components/BasicPageLayout/BasicFormDialog.vue";
import BasicInputGroup from '@/components/BasicPageLayout/BasicInputGroup.vue'
import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
import { Buttons, FormItem, FormItems, SelectOption, UploadFileExtend } from "@/types/BasicComponent";
import { defineComponent, reactive, ref, toRef } from "vue";
import { FileType, fileTypeNameMap } from "@/types/Common";
import { getFileBasePreviewUrl, getFileDownloadUrl } from "@/api/file";
import { copyObject } from "@/utils/common";
import { invalidTradeInfo } from "@/api/trade";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
  components: {
    BasicFormDialog,
    BasicInputGroup,
    BasicImageList,
  },
  emits: ['on-success'],
  setup(props, { emit }) {

    const modalShow = ref(false)
    const formData = reactive<AnyObject>({})

    const fileList = reactive<Partial<UploadFileExtend>[]>([])

    const show = async (tradeInfo: AnyObject) => {
      modalShow.value = true
      fileList.length = 0

      // 获取图片baseUrl
      const baseUrlResp = await getFileBasePreviewUrl()
      const baseImgUrl = baseUrlResp.data.data

      copyObject(formData, tradeInfo)
      console.log(formData);
      formData.files.forEach((fileInfo: AnyObject, index: number) => {
        console.log(fileInfo);
        const url =  fileInfo.fileguid? baseImgUrl + fileInfo.fileguid: baseImgUrl.replace('userFileGuid', 'userFileId') + fileInfo.fileid
        fileList.push({ url, title: fileTypeNameMap[fileInfo.filetypeid as FileType] })
      })
    }

    const getCountModeName = (countMode: number) => {
      if (countMode == 1) {
        return '体积'
      } else if (countMode == 2) {
        return '重量'
      } else if (countMode == 3) {
        return '数量'
      }
      return ''
    }
    const getUnitName = (countMode: number) => {
      if (countMode == 1) {
        return '方'
      } else if (countMode == 2) {
        return '吨'
      } else if (countMode == 3) {
        return '件'
      }
      return ''
    }
    const getAmountField = (countMode: number) => {
      if (countMode == 1) {
        return 'volume'
      } else if (countMode == 2) {
        return 'weight'
      } else if (countMode == 3) {
        return 'quantity'
      }
      return ''
    }

    const footerButtons = reactive<Buttons>([
      {
        type: 'danger',
        label: '作废',
        onClick: async () => {
          await ElMessageBox.confirm('是否确认作废此交易单？', { type: 'warning' })
          await invalidTradeInfo(formData.sourceType, formData.sourceId)
          ElMessage.success('作废成功')
          modalShow.value = false
          emit('on-success')
        }
      },  {
        type: 'default',
        label: '关闭',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      formData,
      fileList,
      FileType,
      modalShow,
      footerButtons,
      show,
      getCountModeName,
      getUnitName,
      getAmountField,
    }
  }
})
