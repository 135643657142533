import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import request, { c6Request } from '@/utils/request'

/** 获取交易信息 */
export function getTradeInfoList(params: C6PageRequest<TradeInfoListQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`/backstage/trade/getTradeInfoPage`, { params })
}

/** 作废交易信息 */
export function invalidTradeInfo(tradeType: number, tradeId: number) {
  return c6Request.post<C6BaseResponse<void>>(`/backstage/trade/invalidTradeInfo/${tradeType}/${tradeId}`)
}

export interface TradeInfoListQueryParams {
  tradeCode?: string,
  jhbName?: string,
  xqjName?: string,
  sourceType?: number,
  publishStatus?: number,
  publishStartTime?: Date,
  publishEndTime?: Date
}