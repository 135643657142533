
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { defineComponent, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { formatTime, formatDate, copyObject } from '@/utils/common'
import { getTradeInfoList, TradeInfoListQueryParams } from '@/api/trade'
import TradeDetailDialog from '@/views/trade/TradeDetailDialog.vue'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    TradeDetailDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<TradeInfoListQueryParams>({})
    
    const tableData = ref<AnyArray>([])
    const selectCount = ref(0)
    const exportRef = ref()
    const tradeDetailDialogRef = ref()
    
    const getTableData = async () => {
      const resp = await getTradeInfoList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = resp.data.data.records
      mapGoodsInfoToData(respData)

      tableData.value = respData
      pagination.total = resp.data.data.total
    }

    const mapGoodsInfoToData = (dataList: AnyArray) => {
      dataList.forEach(data => {
        data.tradeDescription = data.description
        const goodsInfo = data.goodsList?.[0]
        if (!goodsInfo) return
        copyObject(data, goodsInfo)
      })
    }

    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
    })

    const searchInputs = reactive<Inputs>([
      {
        label: '交易单号',
        type: 'input',
        placeholder: '请输入交易单号',
        model: toRef(searchData, 'tradeCode'),
        width: '200px',
        clearable: true
      }, {
        label: '胶合板厂',
        type: 'input',
        placeholder: '请输入胶合板厂',
        model: toRef(searchData, 'jhbName'),
        width: '150px',
        clearable: true
      }, {
        label: '生产机主',
        type: 'input',
        placeholder: '请输入生产机主',
        model: toRef(searchData, 'xqjName'),
        width: '150px',
        clearable: true
      }, {
        label: '交易类型',
        type: 'select',
        placeholder: '请选择交易类型',
        model: toRef(searchData, 'sourceType'),
        width: '150px',
        clearable: true,
        options: [
          { label: '求购', value: 1 },
          { label: '供应', value: 2 }
        ]
      }, {
        label: '发布状态',
        type: 'select',
        placeholder: '请选择发布状态',
        model: toRef(searchData, 'publishStatus'),
        width: '150px',
        clearable: true,
        options: [
          { label: '未发布', value: 1 },
          { label: '已发布', value: 2 },
          { label: '已结束', value: 3 }
        ]
      }, {
        label: '发布时间',
        type: 'group',
        divider: '-',
        inputs: [
          {
            type: 'date',
            placeholder: '请选择开始日期',
            model: toRef(searchData, 'publishStartTime'),
            width: '150px',
            clearable: true
          }, {
            type: 'date',
            placeholder: '请选择结束日期',
            model: toRef(searchData, 'publishEndTime'),
            width: '150px',
            clearable: true
          }, 
        ]
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => exportRef.value.doExport()
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '60px',
        type: 'index',
        align: 'center'
      }, {
        prop: 'tradeCode',
        label: '交易单号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'sourceType',
        label: '类型',
        minWidth: '100px',
        preventFormat: true,
        type: 'status',
        align: 'center',
        statusType: [
          { label: '求购', value: 1, type: 'success' },
          { label: '供应', value: 2, type: 'warning' },
        ]
      }, {
        prop: 'publisherName',
        label: '发布方',
        minWidth: '200px'
      }, {
        prop: 'phone',
        label: '电话',
        minWidth: '150px'
      }, {
        prop: 'contact',
        label: '联系人',
        minWidth: '120px'
      }, {
        prop: 'address',
        label: '地址',
        minWidth: '200px'
      }, {
        prop: 'tradeDescription',
        label: '描述',
        minWidth: '140px'
      }, {
        prop: 'publishStatus',
        label: '发布状态',
        minWidth: '100px',
        type: 'status',
        align: 'center',
        statusType: [
          { label: '未发布', value: 1, type: 'info' },
          { label: '发布中', value: 2, type: 'success' },
          { label: '已结束', value: 3, type: 'warning' }
        ]
      }, {
        prop: 'goodsName',
        label: '货物名称',
        minWidth: '120px'
      }, {
        prop: 'categoryName',
        label: '货物类别',
        minWidth: '120px'
      }, {
        prop: 'unitPrice',
        label: '货物单价(元)',
        minWidth: '130px'
      }, {
        prop: 'volume',
        label: '货物数量',
        minWidth: '150px'
      }, {
        prop: 'totalPrice',
        label: '货物总价(元)',
        minWidth: '150px'
      }, {
        prop: 'description',
        label: '货物规格',
        minWidth: '150px'
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          { 
            label: '详情',
            onClick: scope => {
              tradeDetailDialogRef.value.show(scope.row)
            }
          }
        ]
      }
    ])

    return {
      exportRef,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      selectCount,
      getTableData,
      tradeDetailDialogRef
    }
  }
})
